import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// s4n custom code
// import loadable from '@loadable/component'
// import FragmentPageDetails from './../model/fragments/nodePage/page-details'
import { navigate, useIntl } from "gatsby-plugin-intl"
// import { useParseInlineImages } from '../hooks/drupal/parseInlineImages'
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import Team from './../components/s4n/Team/index-static';
// import ImagesIllustrationsLeafBg from './../assets/images/illustrations/leaf-bg.png';
// import Portfolio from './../components/s4n/Portfolio'
// import Partners from './../components/s4n/Partners'
// import Testimonials from './../components/s4n/Testimonials'

// Subpages
// import kebabCase from "lodash.kebabcase"
// import Link from "./../components/s4n/Link"
// import Page from "./../model/node/page"
// import ImageS4n from './../components/s4n/image'
// import useReplacementNode from './../hooks/drupal/parseContentProcessor/useReplacement/Node'

// import Hero from './../components/s4n/ProjectSpecific/Hero'
// import ProductsList from './../components/s4n/ProjectSpecific/ProductsList'
// import Categories from './../components/s4n/ProjectSpecific/Categories'
// import Categories2 from './../components/s4n/ProjectSpecific/Categories2'
// import Cta from './../components/s4n/ProjectSpecific/Cta'
// import CollectionSlider from './../components/s4n/ProjectSpecific/CollectionSlider'
// import Deal from './../components/s4n/ProjectSpecific/Deal'
// import Instagram from './../components/s4n/ProjectSpecific/Instagram'
// import QuickView from './../components/s4n/ProjectSpecific/QuickView'
// import Brand from './../components/s4n/Shop/Components/Brand'

// import PrivateRoute from './../components/s4n/AccountManagement/PrivateRoute';
// import Home from './../components/s4n/Home';


const IndexPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;
	


	navigate(`/user/profile`);
	return null;



	// const ProductRelated = loadable(() => import(`./../components/s4n/Shop/Product/related`));
/*
    return (
      	<Layout 
		  className={`home__page`}
		//   node={nodeInLanguage}
		>
			<SEO 
				// nodeId={nodeInLanguage.drupal_internal__nid} 
				// title={nodeInLanguage.title} 
				title={t({ id: `siteMetadata_title_home_page` })} 
				// field_metatag={nodeInLanguage.field_metatag}
			/>

			<PrivateRoute component={Home} />

      	</Layout>
    )
*/
}

export default IndexPage
